import React from 'react';
import { graphql } from 'gatsby';
import {
  PageBuilderTemplateProps,
  TPageBuilderComponent
} from '../types/page-builder-types';
import {
  ThemeProvider,
  SolidDarkTheme,
  NavigationThemeKey,
  ThemeColorsWrapper,
  TComponentThemeKey,
  PageBuilderContainer,
  Colors
} from '@parsleyhealth/cilantro-ui';
import { Layout } from '../components/Layout';
import * as PBC from '../components/page-builder';
import { default as SEO } from '../components/SEO';
import Appear from '../components/animations/appear';
import Banner from '../components/Common/Banner';
import { DigiohScript } from '../components/ThirdParty/digioh-script';

export const NonAnimatedComponents = ['SanityHeroNew', 'SanityNonContentBlock'];

export const PageBuilderModule = (props: TPageBuilderComponent) => {
  switch (props.__typename) {
    case 'SanityTextAndImage': {
      return <PBC.TextAndImageComponent {...props} />;
    }

    case 'SanityHeroNew': {
      return <PBC.HeroComponent {...props} />;
    }

    case 'SanityRenewal': {
      return <PBC.RenewalComponent {...props} />;
    }

    case 'SanitySimpleReviewsComponent': {
      return <PBC.SimpleReviewComponent {...props} />;
    }

    case 'SanityTextAndCta': {
      return <PBC.TextAndCtaComponent {...props} />;
    }

    case 'SanityTestimonialsComponent': {
      return <PBC.TestimonialComponent {...props} />;
    }

    case 'SanityTextBlockSection': {
      return <PBC.TextBlockSectionComponent {...props} />;
    }

    case 'SanityCenteredText': {
      return <PBC.CenteredTextComponent {...props} />;
    }

    case 'SanityPlanSnapshotsArray': {
      return <PBC.PlanSnapshotsArrayComponent {...props} />;
    }

    case 'SanityNonContentBlock': {
      return <PBC.NoContentBlock {...props} />;
    }

    case 'SanityColumnImageBlocks': {
      return <PBC.ColumnImageBlocksComponent {...props} />;
    }

    case 'SanityFaqBlockPageBuilder': {
      return <PBC.FaqBlockPageBuilderComponent {...props} />;
    }

    case 'SanityInfographics': {
      return <PBC.InfographicsComponent {...props} />;
    }

    case 'SanityTextImageAndIcons': {
      return <PBC.TextImageAndIconsComponent {...props} />;
    }

    case 'SanityThreeColumnIcon': {
      return <PBC.ThreeColumnIconComponent {...props} />;
    }

    case 'SanityTextAndIcons': {
      return <PBC.TextAndIconsComponent {...props} />;
    }

    case 'SanityTextAndVideoTwoColumn': {
      return <PBC.TextAndVideoTwoColumnComponent {...props} />;
    }

    case 'SanityVideoCarousel': {
      return <PBC.VideoCarouselComponent {...props} />;
    }

    default:
      return (
        <PBC.ErroredModule>
          <div>
            Missing component for type {props['__typename']}. Check the
            following things:
            <ul>
              <li>
                Make sure you have created a file for {props['__typename']} in
                src/components/page-builder
              </li>
              <li>
                Make sure you have declared a component for{' '}
                {props['__typename']} in the pabe builder index file:
                src/components/page-builder/index.ts
              </li>
              <li>
                Make sure you have added a case for {props['__typename']} in the
                PageBuilderModule switch statement in
                src/templates/page-builder-template.tsx
              </li>
              <li>
                Make sure you have added {props['__typename']}Fragment to the
                graphql query in src/templates/page-builder-template.tsx
              </li>
            </ul>
          </div>
        </PBC.ErroredModule>
      );
  }
};

const PageBuilderTemplate = ({
  data: {
    sanityPageBuilderPage: {
      _rawBannerPromo,
      components,
      metaDescription,
      metaImage,
      navigationSettings,
      noindex,
      title
    }
  },
  pageContext: { slug }
}: PageBuilderTemplateProps) => {
  const pathProp = { path: slug };
  const themeNav = navigationSettings
    ? navigationSettings.themeNav
    : SolidDarkTheme;
  const spacer = navigationSettings ? navigationSettings.spacer : null;
  console.log('pbv2', components);
  return (
    <ThemeProvider>
      <SEO
        pageTitle={title}
        pageDescription={metaDescription}
        pageImage={metaImage}
        noindex={noindex}
      />
      <DigiohScript />
      <Layout themeNav={themeNav as NavigationThemeKey} spacer={spacer}>
        {_rawBannerPromo && <Banner {..._rawBannerPromo} />}
        {components.map(props => {
          if (props === null) {
            return (
              <PBC.ErroredModule>
                A page builder component was added to the sanity schema but your
                query is missing a request for those component props. Please
                update your page builder query. <br />
                Check your the queries in the component file and
                src/templates/page-builder-template.tsx
              </PBC.ErroredModule>
            );
          }
          return (
            <ThemeColorsWrapper
              useSeparatorTop={props?.useSeparatorTop}
              useSeparatorBottom={props?.useSeparatorBottom}
              separatorColor={props?.separatorColor as keyof Colors}
              theme={
                'theme' in props && props.theme
                  ? (props.theme as TComponentThemeKey)
                  : 'whiteBackgroundDarkText'
              }
            >
              <PageBuilderContainer>
                {NonAnimatedComponents.includes(props.__typename) ? (
                  <PageBuilderModule {...props} {...pathProp} />
                ) : (
                  <Appear>
                    <PageBuilderModule {...props} {...pathProp} />
                  </Appear>
                )}
              </PageBuilderContainer>
            </ThemeColorsWrapper>
          );
        })}
      </Layout>
    </ThemeProvider>
  );
};

export default PageBuilderTemplate;

// Please sort alphabetically
export const query = graphql`
  query PageBuilderQuery($id: String!) {
    sanityPageBuilderPage(id: { eq: $id }) {
      _rawBannerPromo
      components {
        __typename
        ... on SanityCenteredText {
          ...SanityCenteredTextFragment
        }
        ... on SanityFaqBlockPageBuilder {
          ...SanityFaqBlockPageBuilderFragment
        }
        ... on SanityHeroNew {
          ...SanityHeroNewFragment
        }
        ... on SanityNonContentBlock {
          ...SanityNoContentBlockFragment
        }
        ... on SanityPlanSnapshotsArray {
          ...SanityPlanSnapshotsArrayFragment
        }
        ... on SanityRenewal {
          ...SanityRenewalFragment
        }
        ... on SanitySimpleReviewsComponent {
          ...SanitySimpleReviewFragment
        }
        ... on SanityTestimonialsComponent {
          ...SanityTestimonialFragment
        }
        ... on SanityTextAndCta {
          ...SanityTextAndCtaFragment
        }
        ... on SanityTextAndImage {
          ...SanityTextAndImageFragment
        }
        ... on SanityTextBlockSection {
          ...SanityTextBlockSectionFragment
        }
        ... on SanityColumnImageBlocks {
          ...SanityColumnImageBlocksFragment
        }
        ... on SanityInfographics {
          ...SanityInfographicsFragment
        }
        ... on SanityTextImageAndIcons {
          ...SanityTextImageAndIconsFragment
        }
        ... on SanityThreeColumnIcon {
          ...SanityThreeColumnIconFragment
        }
        ... on SanityTextAndIcons {
          ...SanityTextAndIconsFragment
        }
        ... on SanityTextAndVideoTwoColumn {
          ...SanityTextAndVideoTwoColumnFragment
        }
        ... on SanityVideoCarousel {
          ...SanityVideoCarouselFragment
        }
      }
      metaDescription
      title: metaTitle
      metaImage {
        hotspot {
          y
          x
          width
          height
        }
        crop {
          bottom
          left
          right
          top
        }
        asset {
          url
        }
      }
      navigationSettings {
        themeNav
        spacer
      }
      noindex
    }
  }
`;
